import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BsTelephoneFill, BsLinkedin, BsFacebook } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { ImLocation } from "react-icons/im";
import Markdown from "markdown-to-jsx";
import Carousel from "../components/Carousel";
import Management from "../img/icons/management.png";
import Facilities from "../img/icons/building.png";
import Construction from "../img/icons/construction.png";
import Estimate from "../img/icons/estimate.png";
import Evaluations from "../img/icons/checklist.png";
import ParallaxHeader from "../components/ParallaxHeader";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  title,
  subheading,
  image,
  secondary,
  mainpitch,
  banner,
  slides,
}) => {
  const heroImage = getImage(banner.image) || banner;
  const bannerRef = useRef(null);

  // Y
  const [y, setY] = useState();

  // This function calculate X and Y
  const getPosition = () => {
    const y = bannerRef.current?.offsetTop;
    setY(y);
  };

  // Get the position of the red box in the beginning
  useEffect(() => {
    getPosition();
  }, []);
  return (
    <div>
      <Carousel slides={slides} />
      <section className="flex flex-col items-center justify-center w-full pt-10">
        <div className="container text-center flex flex-col items-center">
          <h1 className="text-brown text-3xl">{title}</h1>
          <p className="text-darkbrown/[0.5]">{subheading}</p>
          <hr className="mb-4 mt-6 border-navy border-b-2 w-full w-[70%]"></hr>
        </div>
      </section>
      <section className="flex flex-col items-center justify-center w-full ">
        <div className="container">
          <div className="p-4 sm:p-20 flex flex-col-reverse md:flex-row justify-between">
            <div className="flex flex-grow">
              <div className="flex flex-col justify-center">
                <h1 className="text-navy text-2xl sm:text-3xl mb-6">
                  {mainpitch.header}
                </h1>
                <Markdown className="md-content md-theme-color">
                  {mainpitch.content}
                </Markdown>
                <Link to={mainpitch.button.link}>
                  <button
                    className={`text-md text-white bg-navy p-3 rounded-sm hover:bg-navyHover w-full sm:w-80 flex flex-row justify-center items-center shadow-md`}
                  >
                    {mainpitch.button.text}
                  </button>
                </Link>
              </div>
            </div>
            <div className="flex w-full md:w-[420px] xl:w-[600px] mb-6 md:mb-0 shrink-0 h-fit md:mx-10 shadow">
              <GatsbyImage
                image={getImage(mainpitch.image)}
                imgStyle={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center justify-center w-full bg-brown/[0.7]">
        <div className="container p-10 flex flex-col items-center justify-center">
          <h1 className="text-white text-center text-2xl">Our Capabilities</h1>
          <hr className="mb-4 mt-6 border-white border-b-2 w-[60%]"></hr>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 mt-10">
            <div className="flex flex-col items-center justify-between ">
              <img src={Management} width={40} height="auto"></img>
              <hr className="border-white" style={{ height: "2px" }}></hr>
              <h1 className="text-white text-center text-2xl mt-3 lg:mt-5">
                Management <br></br>Consulting
              </h1>
            </div>
            <div className="flex flex-col items-center justify-between my-6 lg:my-0">
              <img src={Facilities} width={35} height="auto"></img>
              <h1 className="text-white text-center text-2xl mt-3 lg:mt-5">
                Facilities <br></br>Consulting
              </h1>
            </div>
            <div className="flex flex-col items-center justify-between my-6 lg:my-0">
              <img src={Construction} width={40} height="auto"></img>
              <h1 className="text-white text-center text-2xl mt-3 lg:mt-5">
                Construction Project Management
              </h1>
            </div>
            <div className="flex flex-col items-center justify-between my-6 lg:my-0">
              <img src={Estimate} width={40} height="auto"></img>
              <h1 className="text-white text-center text-2xl mt-3 lg:mt-5">
                Cost <br></br>Estimating
              </h1>
            </div>
            <div className="flex flex-col items-center justify-between my-6 lg:my-0">
              <img src={Evaluations} width={40} height="auto"></img>
              <h1 className="text-white text-center text-2xl mt-3 lg:mt-5">
                Building <br></br>Evaluations
              </h1>
            </div>
          </div>
          <Link to="/capabilities">
            <button className="text-white bg-darkbrown hover:bg-brown px-5 py-3 rounded-sm mt-10">
              Expore Our Capabilities
            </button>
          </Link>
        </div>
      </section>
      <div ref={bannerRef}>
        <ParallaxHeader
          className="h-80 mt-0"
          image={heroImage}
          factor={0.1}
          offset={y - 100}
        >
          <div className="container text-center z-10 p-6 sm:p-20">
            <h1 className="text-white text-2xl sm:text-3xl z-10">
              {banner.header}
            </h1>
            <p className="text-white sm:text-lg mt-4 z-10">{banner.text}</p>
          </div>
        </ParallaxHeader>
      </div>
      <section className="flex flex-col items-center  bg-beige-300/[0.5] justify-center w-full ">
        <div className="container">
          <div className="p-4 sm:p-20 flex flex-col lg:flex-row justify-between">
            <div className="flex w-full lg:w-[400px] mb-6 lg:mb-0 shrink-0 h-fit lg:mx-10 shadow">
              <GatsbyImage
                image={getImage(secondary.image)}
                imgStyle={{ width: "100%", height: "auto" }}
              />
            </div>
            <div className="flex flex-grow lg:text-right">
              <div className="flex flex-col justify-center items-center lg:items-end">
                <h1 className="text-brown text-2xl sm:text-3xl mb-6">
                  {secondary.header}
                </h1>
                <Markdown className="md-content md-theme-color mb-6">
                  {secondary.content}
                </Markdown>
                <Link to={secondary.button.link}>
                  <button
                    className={`text-md text-white bg-navy p-3 w-60 rounded-sm hover:bg-navyHover w-full sm:w-80 flex flex-row justify-center items-center shadow-md`}
                  >
                    {secondary.button.text}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container flex flex-col md:flex-row py-20 px-5 md:px-20 md:mx-20">
        <div className="flex flex-col flex-grow">
          <h1 className="text-brown mb-4 text-3xl">Get in Touch</h1>
          <p className="text-navy text-lg mb-4">
            <ImLocation className="inline mr-2 text-lg" />
            7202 Poplar Street, Suite F{" "}
            <p className="ml-6">Annandale, VA 22003 </p>
          </p>
          <a
            className="text-navy text-lg mb-4  hover:text-navyHover"
            href="tel:7038138901"
          >
            <BsTelephoneFill className="inline mr-2"></BsTelephoneFill>(703)
            813-8901{" "}
          </a>
          <a
            href="mailto:info@bcservices-inc.com"
            className="text-navy hover:text-navyHover text-lg mb-4"
          >
            <GrMail className="inline mr-2" />
            info@bcservices-inc.com
          </a>
        </div>
        <div className="flex w-60 h-auto shrink-0 mx-10">
          <img
            src="/img/logo-color.jpg"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>
    </div>
  );
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <IndexPageTemplate
      image={frontmatter.image}
      title={frontmatter.title}
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      mainpitch={frontmatter.mainpitch}
      secondary={frontmatter.secondarypitch}
      banner={frontmatter.banner}
      slides={frontmatter.carouselitems}
    />
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        carouselitems {
          header
          subheader
          button {
            text
            link
          }
          backgroundimage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        heading
        subheading
        mainpitch {
          header
          content
          image {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 80)
            }
          }
          button {
            text
            link
          }
        }
        secondarypitch {
          header
          content
          image {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 64, layout: CONSTRAINED)
            }
          }
          button {
            text
            link
          }
        }
        banner {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          header
          text
        }
      }
    }
  }
`;
