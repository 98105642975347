import React, { useEffect, useRef, useState } from "react";

import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { GoPrimitiveDot } from "react-icons/go";

export default function Carousel({ slides }) {
  const [slideIndex, setSlideIndex] = useState(0);
  useEffect(() => {
    const next = (slideIndex + 1) % slides.length;
    const id = setTimeout(() => setSlideIndex(next), 7000);
    return () => clearTimeout(id);
  }, [slideIndex]);
  const previousSlide = () => {
    setSlideIndex((current) =>
      current === 0 ? slides.length - 1 : current - 1
    );
  };
  const nextSlide = () => {
    setSlideIndex((current) => (current + 1) % slides.length);
  };
  return (
    <div>
      <div className="flex w-full header-gradient relative min-h-[600px] sm:min-h-[800px] overflow-x-hidden">
        {slides.map((slide, idx) => {
          const image = getImage(slide.backgroundimage);
          return (
            <ImageSlide
              image={image}
              index={idx}
              key={idx}
              currentSlideIdx={slideIndex}
              slide={slide}
            />
          );
        })}
        <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center ">
          <button
            onClick={nextSlide}
            className="hidden sm:block absolute right-4 top-[50%] z-10 text-white opacity-80 hover:opacity-100"
          >
            <MdNavigateNext size="36px" />
          </button>
          <button
            onClick={previousSlide}
            className="hidden sm:block absolute left-4 top-[50%] z-10 text-white opacity-80 hover:opacity-100"
          >
            <MdNavigateBefore size="36px" />
          </button>
          <div className="absolute bottom-5 left-[50%] flex flex-row text-white text-xl translate-x-[-50%]">
            {slides.map((slide, idx) => {
              return (
                <div
                  className={`${
                    idx === slideIndex ? "opacity-90" : "opacity-40"
                  }`}
                >
                  <GoPrimitiveDot />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function ImageSlide({ currentSlideIdx, index, image, slide }) {
  const [shown, setShown] = useState(currentSlideIdx === index);
  useEffect(() => {
    setShown(currentSlideIdx === index);
  }, [currentSlideIdx]);
  useEffect(() => {
    if (shown) {
      animation.start("shown");
    } else {
      animation.start("hidden");
    }
  }, [shown]);
  const animation = useAnimation();
  const headerVariants = {
    shown: {
      opacity: 1,
      x: 0,
      transition: {
        delay: "0.2",
        type: "spring",
        duration: 0.8,
      },
    },
    hidden: {
      opacity: 0,
      x: "-10rem",
      transition: {
        type: "spring",
        duration: 0,
        delay: 1,
        opacity: {
          type: "ease",
          duration: 0.5,
          delay: 0,
        },
      },
    },
  };
  const subheaderVariants = {
    shown: {
      opacity: 1,
      x: 0,
      transition: {
        delay: "0.2",
        type: "spring",
        duration: 0.8,
      },
    },
    hidden: {
      opacity: 0,
      x: "10rem",
      transition: {
        type: "spring",
        duration: 0,
        delay: 1.0,
        opacity: {
          type: "ease",
          duration: 0.5,
          delay: 0,
        },
      },
    },
  };
  const buttonVariants = {
    shown: {
      opacity: 1,
      y: 0,
      display: "block",
      transition: {
        delay: "0.5",
        type: "ease",
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      y: "0.5rem",
      display: "none",
      transition: {
        type: "spring",
        duration: 0,
        delay: 1,
        opacity: {
          type: "ease",
          duration: 0.5,
          delay: 0,
        },
        display: {
          delay: 1,
        },
      },
    },
  };
  return (
    <>
      <div
        className={`absolute w-full h-full top-0 left-0 transition-opacity duration-[1.5s] ease-in-out ${
          shown ? "opacity-100 z-[-2]" : "opacity-0 z-[-3]"
        }`}
      >
        <GatsbyImage
          image={image}
          objectFit={"cover"}
          style={{
            height: "100%",
            width: "100%",
          }}
          layout="fullWidth"
          aspectratio={3 / 1}
          alt=""
          formats={["auto", "webp", "avif"]}
        />
      </div>
      <div
        className={`absolute w-full h-full top-0 left-0 z-0 transition-z ${
          shown ? " z-[1]" : " z-[0]"
        }`}
      >
        <div className="flex flex-col w-full h-full items-center justify-center text-center">
          <div className="container px-2 sm:px-8">
            <div className="mt-10 md:mt-20 text-center sm:text-left flex flex-col items-center md:items-start justify-center">
              <motion.h1
                initial={index === 0 ? "shown" : "hidden"}
                className="text-3xl sm:text-5xl xl:text-6xl text-white mb-4 "
                variants={headerVariants}
                animate={animation}
                style={{ fontFamily: "EurostileBold" }}
              >
                {slide.header}
              </motion.h1>
              <motion.p
                initial={index === 0 ? "shown" : "hidden"}
                className="text-md text-white mb-8"
                variants={subheaderVariants}
                animate={animation}
              >
                {slide.subheader}
              </motion.p>
              <motion.div
                initial={index === 0 ? "shown" : "hidden"}
                variants={buttonVariants}
                animate={animation}
              >
                <AnimatedButton
                  text={slide.button.text}
                  path={`/${slide.button.link}`}
                />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function AnimatedButton({ text, path }) {
  const [iconShown, setIconShown] = useState(false);
  return (
    <Link to={path}>
      <button
        className={`text-md text-white bg-navy p-3 rounded-sm hover:bg-navyHover w-80 flex flex-row justify-center items-center`}
        onMouseEnter={() => {
          setIconShown(true);
        }}
        onMouseLeave={() => {
          setIconShown(false);
        }}
      >
        {text}
        {/* <AnimatePresence>
        {iconShown && (
          <motion.div
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ opacity: 0, x: -50 }}
          >
            test
          </motion.div>
        )}
      </AnimatePresence> */}
      </button>
    </Link>
  );
}
